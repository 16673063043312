import React from "react";
import { Helmet } from "react-helmet-async";

// "maximum-scale=1" preventing all scaling behavior (i.e. autozooming)
// can have side-effect to dynamically render diff <meta /> if we want to support touch zooming in the future
const Viewport = () => (
  <Helmet>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
    />
  </Helmet>
);

export { Viewport };
