import React from "react";
import withI18n from "i18n/withI18n";
import { PageProps } from "gatsby";

import Teaser from "components/Teaser";
import SEO from "components/seo";
import { Viewport } from "components/Meta";

import { Interests, WhitepaperRequstTags } from "constants/mailchimp";

import pqscaleIcon from "assets/ic_pqscale.svg";
import SignatureIcon from "assets/ic_signature.inline.svg";
import CompressIcon from "assets/ic_compress.inline.svg";
import APIIcon from "assets/ic_api.inline.svg";

const description =
  "A novel scaling technique for post-quantum cryptographic primitives. PQScale achieves transaction speed and cost savings by leveraging zero-knowledge proofs for transaction processing.";

const PQScaleTeaser = ({ location }: PageProps) => (
  <>
    <Viewport />
    <SEO
      title="PQScale"
      description={description}
      pathname={location.pathname}
      titlePrefix
      // locale // ToDo: enable this when i18n is ready
    />
    <Teaser
      title={{ name: "PQScale", iconUrl: pqscaleIcon }}
      desc={description}
      features={[
        {
          icon: SignatureIcon,
          outline: <span className="-mr-5 block">Post-Quantum Secure</span>,
          desc: (
            <>
              Underpinned by the NIST-approved Falcon digital signature algorithm. Generalizable to
              other lattice-based cryptosystems.
            </>
          ),
        },
        {
          icon: CompressIcon,
          outline: "27x Smaller",
          desc: (
            <>
              Improved compression ratio as the number of signatures aggregated increases. This
              means that more space is saved with the more signatures included in an aggregate
              signature.
            </>
          ),
        },
        {
          icon: APIIcon,
          outline: "API Accessible",
          desc: <>Remotely access PQScale using our RESTful API.</>,
        },
      ]}
      interests={[Interests.PQScale]}
      whitepaperTag={WhitepaperRequstTags.PQScale}
    />
  </>
);

export default withI18n(PQScaleTeaser);
